import Alpine from 'alpinejs';

(window as typeof window & { Alpine: typeof Alpine }).Alpine = Alpine;

document.addEventListener('alpine:init', () => {

  Alpine.data('common', function() {
    return {
    }
  })

});

Alpine.start();

